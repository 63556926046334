import apiSlice from '@base/redux/apiSlice';

const adminAudits = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStats: builder.query<InternalStats, null>({
            query: () => ({
                url: 'admin/stats',
                method: 'GET',
            }),
        }),
        getUsersSnapshot: builder.query<UserSnapshots, { options?: QueryFilterOptions }>({
            query: ({ options }) => ({
                url: `admin/snapshots/users${options}`,
                method: 'GET',
            }),
        }),
        getOrganizationsSnapshot: builder.query<OrganizationSnapshots, { options?: QueryFilterOptions }>({
            query: ({ options }) => ({
                url: `admin/snapshots/organizations${options}`,
                method: 'GET',
            }),
        }),
    }),
});

export default adminAudits;
export const { useGetStatsQuery, useGetUsersSnapshotQuery, useGetOrganizationsSnapshotQuery } = adminAudits;
