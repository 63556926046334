/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, CSSProperties, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import queryFilterOptions from '@base/components/queryFilterOptions';
import { TypedMutationTrigger, TypedUseMutationResult, TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@base/redux/apiSlice';
import Nav from '@base/components/Meta/Nav';
import { default as NavPagination } from '@base/components/Meta/Pagination';
// Ignore ESLint as Mutations can truly be <any>

const useMeta = (
    trigger: TypedMutationTrigger<any, any, typeof customBaseQuery>,
    state: TypedUseMutationResult<any, any, typeof customBaseQuery>,
    {
        triggerParams,
        showPagination = true,
        useSearch = true,
        useTrashed = true,
        sortSize = false,
        sortLastActive = false,
        sortName = true,
        sortFirst = false,
        sortLast = false,
        tags,
        initialTags,
        include,
        queryStrings,
        skip = false,
        initialLimit = 25,
        initialSortBy = '-created',
    }: {
        triggerParams?: any;
        showPagination?: boolean;
        useSearch?: boolean;
        useTrashed?: boolean;
        sortSize?: boolean;
        sortLastActive?: boolean;
        sortName?: boolean;
        sortFirst?: boolean;
        sortLast?: boolean;
        tags?: TypedUseQueryHookResult<Tag[], any, typeof customBaseQuery>;
        initialTags?: string[];
        include?: string;
        queryStrings?: { [key: string]: string | number | boolean | undefined | (string | number)[] };
        skip?: boolean;
        initialLimit?: number;
        initialSortBy?: Sorting['by'];
    } = { triggerParams: { options: '' } }
) => {
    const [isSkipping, setIsSkipping] = useState<boolean>(skip);
    const [page, updatePage] = useState<number>(1);
    const [pageLimit, updatePageLimit] = useState<number>(1);
    const [limit, updateLimit] = useState<number>(initialLimit ?? 25);
    const [rowCount, updateRowCount] = useState<number>(0);
    const [sortBy, updateSortBy] = useState<Sorting['by']>(initialSortBy);
    const [showTrashed, updateShowTrashed] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [nameSearchDebounce, setNameSearchDebounce] = useState<string>('');
    const [currentParams, setCurrentParams] = useState(triggerParams);
    const [filterTags, setFilterTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [currentData, setCurrentData] = useState<any>();

    const { data } = useMemo(() => {
        return state;
    }, [state]);

    const queryOptions = useMemo(() => {
        return queryFilterOptions({
            limit,
            page,
            ...(include && { include }),
            ...(useSearch && { name }),
            sort: sortBy,
            showTrashed,
            tags: filterTags.join(','),
            ...queryStrings,
        });
    }, [page, limit, name, sortBy, showTrashed, include, useSearch, skip, queryStrings, filterTags]);

    const nextPage = () => {
        if (page < currentData?.meta?.last_page) updatePage((prevState) => prevState + 1);
    };
    const prevPage = () => {
        //Check if on first page
        if (page > 1) updatePage((prevState) => prevState - 1);
    };

    const setPage = (page: number) => {
        updatePage(page);
    };

    const setLimit = (limit: number) => {
        updateLimit(limit);
    };

    const setSortBy = (sortBy: Sorting['by']) => {
        console.log('setSortBy', sortBy);
        updateSortBy(sortBy);
    };

    const Pagination = () => createElement(NavPagination, { page, pageLimit, updatePage });

    const nav = ({ style }: { style?: CSSProperties }) =>
        createElement(Nav, {
            style,
            nameSearchDebounce,
            setNameSearchDebounce,
            setName,
            useSearch,
            initialSortBy,
            setSortBy,
            pageLimit,
            showPagination,
            Pagination,
            name,
            setSelectedTags,
            selectedTags,
            sortSize,
            sortLastActive,
            sortName,
            sortFirst,
            sortLast,
            useTrashed,
            showTrashed,
            updateShowTrashed,
        });

    const handleTagChipClicked = (tag: string): void => {
        setSelectedTags((selectedTags) => {
            const selected = selectedTags.find((item) => item.name === tag);
            let match: Tag | undefined;

            if (selected) {
                return selectedTags.filter((item) => item.id !== selected.id);
            }

            if (tags?.data) {
                match = tags.data.find((item) => item.name === tag);
            }

            return match ? [...selectedTags, match] : selectedTags;
        });
    };

    /**
     *  Checks if triggerParams have updated, prevents infinite loop
     */
    useEffect(() => {
        if (triggerParams && JSON.stringify(currentParams) !== JSON.stringify(triggerParams)) {
            setCurrentParams(triggerParams ?? {});
        }

        if (skip !== isSkipping) {
            setIsSkipping(skip);
        }
    }, [triggerParams, skip]);

    /**
     * actual trigger effect
     */
    useEffect(() => {
        //something changed let's update
        !isSkipping && trigger({ options: queryOptions, ...currentParams });
    }, [queryOptions, currentParams, isSkipping]);

    useEffect(() => {
        if (data !== currentData) {
            setCurrentData(data);
        }
    }, [data]);

    /**
     * search Debounce Effect
     */
    useEffect(() => {
        if (nameSearchDebounce.length === 0) {
            return;
        }

        const timeout = setTimeout(() => {
            setPage(1);
            setName(nameSearchDebounce);
        }, 200);
        return () => clearTimeout(timeout);
    }, [nameSearchDebounce]);

    /**
     * Updates page number limit
     */
    useLayoutEffect(() => {
        if (currentData && currentData.meta) {
            if (pageLimit !== currentData?.meta?.last_page) {
                updatePageLimit(currentData.meta.last_page);
            }
            if (rowCount !== currentData?.meta?.total) {
                updateRowCount(currentData?.meta?.total);
            }
        }
    }, [currentData]);

    useEffect(() => {
        //todo init
        if (tags?.data && initialTags) {
            setFilterTags(initialTags);
            setSelectedTags(tags.data.filter((tag) => initialTags.includes(tag.name)));
        }
    }, [tags, initialTags]);

    useEffect(() => {
        const timeout = setTimeout(() => setFilterTags(selectedTags.map((item) => item.name)), 250);
        return () => clearTimeout(timeout);
    }, [selectedTags]);

    return {
        Nav: nav,
        page: {
            next: nextPage,
            prev: prevPage,
            set: setPage,
            current: page,
        },
        rowCount,
        pageLimit,
        Pagination,
        setLimit,
        setSortBy,
        handleTagChipClicked,
        queryOptions,
        showTrashed,
        data: currentData,
    };
};

export default useMeta;
/* eslint-enable @typescript-eslint/no-explicit-any */
