import { Box, FormControl, InputLabel, NativeSelect, Typography } from '@mui/material';
import { useGetUsersSnapshotQuery } from '@intractinc/base/redux/features/adminStats';
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { LineChartPro } from '@mui/x-charts-pro';
import { SeriesLegendItemContext } from '@mui/x-charts/ChartsLegend/chartsLegend.types';
import queryFilterOptions from '@intractinc/base/components/queryFilterOptions';

const RecentUsersChart = () => {
    const { classes, theme } = useStyles();
    const [polling, setPolling] = useState(300000); // time in ms (default 5 min)
    const [interval, setInterval] = useState(1); // intervals / page (limit)
    const [history, setHistory] = useState(60);
    const [visible, setVisible] = useState<{ [key: number | string]: boolean }>({
        paid_members: true,
        recently_active: true,
        total: true,
        trashed: true,
        unverified: true,
    });

    useEffect(() => {
        console.log('test', Math.floor(history / interval), history, interval);
    }, [history, interval]);

    const calculateInterval = (num: number = history) => {
        const maxRows = 1000;

        // If the requested history is more than the limit, calculate the limit
        let minuteInterval: number;
        let limit: number;

        // First, check for requested history less than or equal to 1 hour (60 minutes)
        if (num <= 60) {
            minuteInterval = 1; // 1 minute interval for the first hour
            limit = num; // One row per minute
        } else {
            // For more than 60 minutes, calculate the minuteInterval and limit
            // let maxMinutesForLimit = maxRows; // Number of rows is limited to maxRows (500)

            // Adjust the interval to meet the row limit
            minuteInterval = Math.ceil(num / maxRows);

            // Ensure the minuteInterval is at least 1 minute and doesn't exceed the requested history
            minuteInterval = Math.min(minuteInterval, num);

            // Calculate the limit based on the minuteInterval
            limit = Math.ceil(num / minuteInterval);

            // Ensure the limit does not exceed the maximum limit (500 rows)
            limit = Math.min(limit, maxRows);
        }
        console.log('interval', minuteInterval, limit);
        return { minuteInterval, limit };
    };

    const options = queryFilterOptions({
        limit: Math.floor(history / interval),
        filters: {
            minuteInterval: interval,
        },
    });

    const { data } = useGetUsersSnapshotQuery(
        { options },
        {
            pollingInterval: polling,
        }
    );

    const fields: { [key: string]: string } = {
        paid_members: 'Paid Members',
        recently_active: 'Recently Active',
        total: 'Total',
        trashed: 'Trashed',
        unverified: 'Unverified',
    };

    const colors: { [key: string]: string } = {
        paid_members: theme.palette.success.dark,
        recently_active: theme.palette.intract.main,
        total: theme.palette.info.light,
        trashed: theme.palette.error.main,
        unverified: theme.palette.warning.light,
    };

    const recentUsers = useMemo(
        () =>
            !data?.data
                ? []
                : data.data
                      // .filter((snapshot, index) => (index + 1) % (history >= 360 ? 8 : 1) === 0 && snapshot)
                      .map((snapshot, index) => {
                          return {
                              created_at: new Date(snapshot.created_at).toLocaleDateString(
                                  'US',

                                  {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      day: '2-digit',
                                      month: '2-digit',
                                      localeMatcher: 'best fit',
                                      formatMatcher: 'best fit',
                                  }
                              ),
                              paid_members: visible['paid_members'] ? snapshot.paid_member : null,
                              total: visible['total'] ? snapshot.total : null,
                              trashed: visible['trashed'] ? snapshot.trashed : null,
                              recently_active: visible['recently_active'] ? snapshot.recently_active : null,
                              unverified: visible['unverified'] ? snapshot.unverified : null,
                          };
                      })
                      .reverse(),
        [data, visible, history]
    );

    const stackStrategy = {
        stack: 'total',
        area: false,
        // stackOffset: 'none', // To stack 0 on top of others
    } as const;

    const handleLegendClick = (
        event: React.MouseEvent<SVGRectElement, MouseEvent>,
        legendItem: SeriesLegendItemContext,
        index: number
    ) => {
        setVisible({ ...visible, [legendItem.seriesId]: !visible[legendItem.seriesId] });
    };

    const handlePollingChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setPolling(parseInt(event.target.value) * 60000);
    };

    const handleIntervalChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const interval = parseInt(event.target.value);
        const check = calculateInterval(history);
        const update = interval >= check.minuteInterval ? interval : check.minuteInterval;
        setInterval(update);
    };

    const handleHistoryChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const check = calculateInterval(parseInt(event.target.value));
        console.log('check', check, interval);
        setInterval(check.minuteInterval);
        setHistory(parseInt(event.target.value));
    };

    return (
        <div className={classes.root}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mb={1}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography
                        variant={'h6'}
                        color={'primary'}
                        display={'flex'}
                        flexDirection={'row'}
                        alignContent={'center'}
                    >
                        Recent Users:
                    </Typography>
                    <Typography variant={'h6'} ml={1}>
                        {data?.data[data?.data.length - 1].recently_active}
                    </Typography>
                </Box>

                <Box>
                    <FormControl sx={{ mr: 2 }}>
                        <InputLabel id={'polling-label'} variant={'standard'} htmlFor={'polling'}>
                            Polling
                        </InputLabel>
                        <NativeSelect
                            inputProps={{ name: 'polling', id: 'polling' }}
                            defaultValue={5}
                            onChange={handlePollingChange}
                        >
                            <option value={1}>1 min</option>
                            <option value={5}>5 min</option>
                            <option value={10}>10 min</option>
                            <option value={15}>15 min</option>
                            <option value={20}>20 min</option>
                        </NativeSelect>
                    </FormControl>

                    <FormControl sx={{ mr: 2 }}>
                        <InputLabel id={'interval-label'} variant={'standard'} htmlFor={'interval'}>
                            Interval
                        </InputLabel>
                        <NativeSelect
                            inputProps={{ name: 'interval', id: 'interval' }}
                            defaultValue={1}
                            onChange={handleIntervalChange}
                        >
                            <option value={1}>1 min</option>
                            <option value={5}>5 min</option>
                            <option value={10}>10 min</option>
                            <option value={15}>15 min</option>
                            <option value={20}>20 min</option>
                            <option value={30}>30 min</option>
                            <option value={60}>1 hour</option>
                            <option value={360}>6 hours</option>
                            <option value={720}>12 hours</option>
                            <option value={1440}>1 Day</option>
                            <option value={2880}>2 Days</option>
                            <option value={7200}>5 Days</option>
                            <option value={21600}>15 Days</option>
                            <option value={43200}>30 Days</option>
                        </NativeSelect>
                    </FormControl>

                    <FormControl>
                        <InputLabel id={'history-label'} variant={'standard'} htmlFor={'history'}>
                            History
                        </InputLabel>
                        <NativeSelect
                            inputProps={{ name: 'history', id: 'history' }}
                            defaultValue={1440}
                            onChange={handleHistoryChange}
                        >
                            <option value={5}>5 min</option>
                            <option value={15}>15 min</option>
                            <option value={30}>30 min</option>
                            <option value={60}>1 hour</option>
                            <option value={360}>6 hours</option>
                            <option value={720}>12 hours</option>
                            <option value={1440}>1 Day</option>
                            <option value={2880}>2 Days</option>
                            <option value={7200}>5 Days</option>
                            <option value={21600}>15 Days</option>
                            <option value={43200}>30 Days</option>
                        </NativeSelect>
                    </FormControl>
                </Box>
            </Box>
            <LineChartPro
                dataset={recentUsers}
                xAxis={[
                    {
                        dataKey: 'created_at',
                        scaleType: 'point',
                    },
                ]}
                series={Object.keys(fields).map((key) => ({
                    type: 'line',
                    dataKey: key,
                    label: fields[key],
                    color: colors[key],
                    showMark: false,
                    id: key,
                    stackOrder: 'ascending',
                    ...stackStrategy,
                }))}
                resolveSizeBeforeRender
                margin={{ top: 5, bottom: 75 }}
                slotProps={{
                    legend: {
                        labelStyle: { fontSize: '12px' },
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        onItemClick: handleLegendClick,
                    },
                    axisTickLabel: {
                        // style: { transform: 'rotate(-30deg)' },
                    },
                }}
                sx={{ width: '100%' }}
            />
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        overflow: 'auto',
        minHeight: 350,
        height: '100%',
        maxHeight: 'calc(50vh - 20px)',
        scrollbarWidth: 'none',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(1),
        '& > *': {
            transitionDuration: '0s',
        },
    },
}));

export default RecentUsersChart;
