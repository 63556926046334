import { createContext, use } from 'react';

const WorkspaceContext = createContext<WorkspaceContextType>({
    isOrganizationMemberPending: false,
    isOrganizationMemberNeeding2FA: false,
    isViewingOrg: false,
    isViewingProject: false,
    organizationSocket: undefined,
    projectSocket: undefined,
    refetchOrganization(): void {},
    refetchProject(): void {},
    refetchProjects(): void {},
});

const useWorkspace = () => {
    return use(WorkspaceContext);
};

export default WorkspaceContext;
export { useWorkspace };
