import { useGetRecentUsersMutation } from '@intractinc/base/redux/features/adminUser';
import { Typography } from '@mui/material';
import ReactTimeAgo from 'react-timeago';
import { makeStyles } from 'tss-react/mui';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useMeta from '@intractinc/base/hooks/useMeta';
import { useState } from 'react';

const RecentUsersTable = () => {
    const { classes } = useStyles();
    const [recentUsers, recentUsersState] = useGetRecentUsersMutation();
    const [pageMeta, setPageMeta] = useState({ page: 0, pageSize: 15 });
    const [columnVisibility, setColumnVisibility] = useState<VisibleColumns>({
        name: true,
    });

    const {
        data: users,
        page,
        pageLimit,
        setLimit,
        rowCount,
        setSortBy,
    } = useMeta(recentUsers, recentUsersState, {
        sortFirst: true,
        sortLast: true,
        sortName: false,
        include: 'projectsCount,membersCount,currentSubscription',
        initialLimit: pageMeta.pageSize,
    });

    const fields: GridColDef[] = [
        { field: 'name', headerName: 'Name', minWidth: 150 },
        { field: 'email', headerName: 'Email', minWidth: 175 },
        {
            field: 'is_verified',
            headerName: 'Verified',

            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridRenderCellParams<User>) =>
                params.value ? (
                    <CheckIcon sx={{ color: 'success.main' }} />
                ) : (
                    <CloseIcon sx={{ color: 'error.main' }} />
                ),
        },
        {
            field: 'created_at',
            headerName: 'Joined',
            renderCell: (params: GridRenderCellParams<User>) => (
                <ReactTimeAgo key={`${params.row.id}_created`} date={new Date(params.value)} />
            ),
        },
    ];

    const handelColumnVisibilityChange = (model: VisibleColumns) => {
        setColumnVisibility(model);
    };

    return (
        <div className={classes.root}>
            <Typography variant={'h5'} color={'intract.main'}>
                Recent Users
            </Typography>
            <DataGridPremium
                density={'compact'}
                pagination
                loading={recentUsersState.isLoading}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={handelColumnVisibilityChange}
                columns={fields}
                disableAggregation={true}
                rows={users?.data ?? []}
                rowCount={rowCount}
                pageSizeOptions={[10, 15, 25]}
                paginationMode={'server'}
                sortingMode={'server'}
                filterMode={'server'}
                autoPageSize
                autosizeOnMount
                paginationModel={{ pageSize: pageMeta.pageSize, page: pageMeta.page }}
                paginationMeta={{ hasNextPage: page.current < pageLimit }}
                onPaginationModelChange={(paginationModel) => {
                    page.set(paginationModel.page + 1);
                    setLimit(paginationModel.pageSize);
                    setPageMeta(paginationModel);
                    return paginationModel;
                }}
                onSortModelChange={(sortModel) => {
                    console.log('sortModel', sortModel);
                    setSortBy(
                        sortModel.reduce((previousValue, currentValue, index, array) => {
                            const field = currentValue.field.replace(/(_\w)/g, (k) => k[1].toUpperCase());
                            console.log(
                                'field',
                                currentValue,
                                `${index === 0 ? previousValue : `${previousValue},`}${currentValue.sort === 'desc' ? '-' : ''}${field}`
                            );
                            return `${index === 0 ? previousValue : `${previousValue},`}${currentValue.sort === 'desc' ? '-' : ''}${field}`;
                        }, '')
                    );
                }}
            />
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            minHeight: 400,
        },
    },
}));

export default RecentUsersTable;
