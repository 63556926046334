import ToolsProvider from '@base/providers/ToolsProvider';
import WorkspaceProvider from '@base/providers/WorkspaceProvider';
import usePageTracking from '@base/hooks/usePageTracking';
import { useAppDispatch, useAppSelector } from '@base/redux/hooks';
import useTabSync from '@base/hooks/useTabSync';
import { useEffect, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { clearXSRF } from '@base/redux/reducers/authSlice';
import { toggleDarkMode } from '@base/redux/reducers/userSlice';
import defaultTheme from '@base/config/theme';
import { useHeartbeatQuery } from '@base/redux/features/auth';
import EchoProvider from '@base/providers/EchoProvider';

const BaseProvider = ({
    children,
    configTheme,
    theme,
}: {
    children: ReactNode;
    configTheme?: (darkMode: boolean) => ThemeOptions;
    theme?: ThemeOptions;
}) => {
    //Init Redux
    useHeartbeatQuery(undefined, { pollingInterval: 300000 });

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);

    usePageTracking();
    useTabSync();

    //Get OS Dark/Lite mode as initial default
    const osDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = useMemo(() => {
        return user.settings.darkMode ?? osDarkMode;
    }, [osDarkMode, user.settings.darkMode]);

    useEffect(() => {
        return () => {
            //Clear XSRF So it's not set for next session
            dispatch(clearXSRF());
        };
    }, []);

    const generatedTheme = useMemo(() => {
        const darkMode = user.settings.darkMode ?? prefersDarkMode;

        //Storybook handles theme switching
        if (theme) {
            return createTheme(theme);
        }

        // We got the theme from the app
        if (configTheme) {
            return createTheme(configTheme(darkMode));
        }

        return createTheme(defaultTheme(darkMode));
    }, [prefersDarkMode, user.settings.darkMode, theme]);

    useEffect(() => {
        dispatch(toggleDarkMode(prefersDarkMode));
    }, [prefersDarkMode]);

    return (
        <ToolsProvider>
            <EchoProvider>
                {import.meta.env.VITE_APP !== 'admin' ? (
                    <WorkspaceProvider>
                        <ThemeProvider theme={generatedTheme}>{children}</ThemeProvider>
                    </WorkspaceProvider>
                ) : (
                    <ThemeProvider theme={generatedTheme}>{children}</ThemeProvider>
                )}
            </EchoProvider>
        </ToolsProvider>
    );
};

export default BaseProvider;
