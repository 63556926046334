import { FC, PropsWithChildren, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, LinkOwnProps } from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

type IntractLinkProps = {
    to: string;
    iconColor?: boolean; // Allow overriding icon colors defaults to true
} & LinkOwnProps &
    PropsWithChildren;

const IntractLink: FC<IntractLinkProps> = ({ to, children, iconColor = false, ...props }) => {
    const location = useLocation();
    const linkActive = location.pathname === to;
    const { classes } = useStyles({ linkActive, iconColor });

    useEffect(() => {
        console.log('linkActive: ', linkActive, iconColor);
    }, [linkActive]);

    return (
        <Link className={classes.root} component={RouterLink} to={to} {...props}>
            {children}
        </Link>
    );
};

const useStyles = makeStyles<{ linkActive: boolean; iconColor: boolean }>()((theme, { linkActive, iconColor }) => ({
    root: {
        color: theme.palette.text.primary,
        '& .MuiListItemIcon-root': {
            ...(linkActive && iconColor && { color: theme.palette.primary.main }),
        },
    },
}));

export default IntractLink;
